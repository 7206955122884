<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    iconName: string
    iconClass?: object | string | null
    text?: string | null
    withHover?: boolean
  }>(),
  { iconClass: null, text: null, withHover: false }
)

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<template>
  <div class="button-with-badge">
    <SvgIcon :class="[iconClass, { 'with-hover': withHover }]" :name="iconName" @click="emit('click')" />

    <div v-if="text !== null" class="button-with-badge__badge">
      {{ text }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.button-with-badge {
  display: inline-block;
  position: relative;

  svg {
    cursor: pointer;
    font-size: 24px;
    transition: color .3s ease;

    &.with-hover:hover {
      color: $main-green-color;
    }
  }

  &__badge {
    align-items: center;
    background: $main-green-color;
    border-radius: 8px;
    color: white;
    display: flex;
    font-size: 11px;
    justify-content: center;
    line-height: 11px;
    min-width: 10px;
    padding: 3px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -25%);
    white-space: nowrap;
  }
}
</style>
