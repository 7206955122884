<script lang="ts" setup>
import helpers from '~/helpers'
import type { NewCategoryMenuItem } from '~/types/menus'

const props = defineProps<{
  data: NewCategoryMenuItem
  iconName?: string
}>()

const categoryPath = computed(() => {
  const { categoryId: id, categorySlug: slug } = props.data

  return id === null || slug === null ? undefined : helpers.getCategoryPath({ id, attributes: { slug } })
})
</script>

<template>
  <nuxt-link class="new-catalog-menu-link" :to="categoryPath">
    {{ data.name }}

    <SvgIcon v-if="iconName" class="new-catalog-menu-link__icon" :name="iconName" />
  </nuxt-link>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.new-catalog-menu-link {
  color: $main-gray-color;
  text-decoration: none;

  &:hover {
    color: $main-green-color;
  }

  &:not([href]) {
    color: $main-red-color;
  }

  &__icon {
    font-size: 24px;
  }
}
</style>
