<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useModal } from 'vue-final-modal'

import Auth from '~/components/modal-windows/Auth.vue'
import AuthAction from '~/enums/auth-action'
import Page from '~/enums/page'
import helpers from '~/helpers'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import { useUserStore } from '~/store/user'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const productsStore = useProductsStore()
const userStore = useUserStore()

const { hasAccessToAdminPanel, isSupplier } = storeToRefs(userStore)

const items = computed(() => {
  const { cartTotalItems, wishListTotalItems } = productsStore
  const items = [
    {
      badgeText: null,
      icon: 'outlined/home',
      isAuthRequired: false,
      path: Page.Main,
      title: 'Главная'
    },
    {
      badgeText: null,
      icon: 'outlined/grid-view',
      isAuthRequired: false,
      path: Page.Products,
      title: 'Каталог'
    },
    {
      badgeText: cartTotalItems === 0 ? null : helpers.formatBadgeText(cartTotalItems),
      icon: 'outlined/shopping-cart',
      isAuthRequired: false,
      path: Page.Cart,
      title: 'Корзина'
    },
    {
      badgeText: wishListTotalItems === 0 ? null : helpers.formatBadgeText(wishListTotalItems),
      icon: 'outlined/favorite-border',
      isAuthRequired: false,
      path: Page.Favorites,
      title: 'Избранное'
    }
  ]

  let lastItem

  if (authStore.isUser) {
    let extraProps

    switch (true) {
      case hasAccessToAdminPanel.value:
        extraProps = { icon: 'outlined/display-settings', path: Page.Admin, title: 'АП' }
        break
      case isSupplier.value:
        extraProps = { icon: 'outlined/display-settings', path: Page.Supplier, title: 'ЛКП' }
        break
      default:
        extraProps = { icon: 'outlined/account-circle', path: Page.Account, title: 'ЛК' }
        break
    }

    lastItem = { badgeText: null, isAuthRequired: true, ...extraProps }
  } else {
    lastItem = {
      badgeText: null,
      icon: 'outlined/account-circle',
      isAuthRequired: true,
      path: Page.Account,
      title: 'Вход'
    }
  }

  items.push(lastItem as any)

  return items
})

async function open (path: string, isAuthRequired: boolean): Promise<void> {
  if (isAuthRequired && !authStore.isUser) {
    await useModal({ component: Auth, attrs: { action: AuthAction.SignIn } }).open()
  } else {
    await router.push(path)
  }
}
</script>

<template>
  <ul class="bottom-navigation-menu">
    <li
      v-for="x in items"
      :key="x.path"
      :class="{
        active: (route.path === Page.Main && x.path === Page.Main)
          || (x.path !== Page.Main && route.path.indexOf(x.path) === 0)
      }"
      @click="open(x.path, x.isAuthRequired)"
    >
      <div>
        <ButtonWithBadge :icon-name="x.icon" :text="x.badgeText" />
        <span>{{ x.title }}</span>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.bottom-navigation-menu {
  align-items: center;
  backdrop-filter: blur(15px);
  background: rgba(white, 90%);
  border-top: 1px solid mix($main-gray-color, white, 20%);
  bottom: 0;
  box-sizing: border-box;
  display: none;
  height: 50px;
  justify-content: center;
  left: 0;
  list-style: none;
  position: fixed;
  width: 100%;
  z-index: 999;

  @media screen and (max-width: 600px) {
    display: flex;
  }

  & > li {
    align-items: center;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    text-align: center;

    & span {
      display: block;
      font-size: 12px;
      line-height: 12px;
      margin-top: 2px;
    }

    &.active {
      color: $main-blue-color;
    }
  }
}
</style>
