<template>
  <div class="new-catalog-menu-title mb16">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.new-catalog-menu-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 26px;
}
</style>
