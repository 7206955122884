<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import ContactUs from '~/components/modal-windows/ContactUs.vue'
import MissingProduct from '~/components/modal-windows/MissingProduct.vue'
import constants from '~/constants'
import Page from '~/enums/page'

const { appStore, googlePlay } = constants.appUrls
const { dataProtectionPolicy, offerForSuppliers, userAgreement } = constants.documentUrls
const appMarketplaces = [
  { ariaLabel: 'App Store', href: appStore, icon: 'app-marketplaces/app-store' },
  { ariaLabel: 'Google Play', href: googlePlay, icon: 'app-marketplaces/google-play' }
]
const informationMenuItems = [
  { name: 'Пользовательское соглашение', url: userAgreement },
  { name: 'Согласие на обработку персональных данных', url: dataProtectionPolicy },
  { name: 'Оферта для поставщиков', url: offerForSuppliers }
]
const onlineShopMenuItems = [
  { name: 'О нас', path: Page.About },
  // { name: 'Мы в офлайне', path: Page.Offline },
  { name: 'Продукты', path: Page.Products },
  { name: 'Оплата заказов', path: Page.Payment },
  { name: 'Способы доставки', path: Page.Delivery },
  { name: 'Возврат', path: Page.Return },
  { name: 'Программа лояльности для специалистов', path: Page.Partner },
  { name: 'Оптовым клиентам', path: Page.Distributor },
  { name: 'Поставщикам', path: Page.Supplier },
  { name: 'Блог', path: `${Page.Articles}?isBlog=yes` }
]
const paymentSystems = [
  { ariaLabel: 'Visa', href: 'https://www.visa.com.ru', icon: 'payment-systems/visa' },
  { ariaLabel: 'Mastercard', href: 'https://www.mastercard.ru', icon: 'payment-systems/mastercard' },
  { ariaLabel: 'Мир', href: 'https://mironline.ru', icon: 'payment-systems/mir' }
]
const socialNetworks = [
  { ariaLabel: 'Telegram', href: 'https://t.me/itabpro', icon: 'social-networks/telegram' },
  { ariaLabel: 'VK', href: 'https://vk.com/itabpro', icon: 'social-networks/vk' },
  { ariaLabel: 'Одноклассники', href: 'https://ok.ru/group/63163727478958', icon: 'social-networks/odnoklassniki' },
  { ariaLabel: 'YouTube', href: 'https://www.youtube.com/@itabpro1', icon: 'social-networks/youtube' },
  { ariaLabel: 'TikTok', href: 'https://www.tiktok.com/@itabpro', icon: 'social-networks/tiktok' }
]
const supportMenuItems = [
  { name: 'Контакты', path: Page.Contacts },
  { name: 'Часто задаваемые вопросы (FAQ)', path: Page.Faq }
]

async function openContactUsModalWindow (attrs = {}): Promise<void> {
  await useModal({ component: ContactUs, attrs }).open()
}

async function openMissingProductModalWindow (): Promise<void> {
  await useModal({ component: MissingProduct, attrs: { textPrefix: 'Недостающий товар' } }).open()
}
</script>

<template>
  <footer>
    <div class="social-networks">
      <a
        v-for="x in socialNetworks"
        :key="x.href"
        :aria-label="x.ariaLabel"
        :href="x.href"
        target="_blank"
      ><SvgIcon :name="x.icon" /></a>
    </div>

    <div class="support-wrapper">
      <div class="container">
        <div class="support">
          <div>
            <div class="support__title">
              Поддержка онлайн-заказов
            </div>

            <div class="support__schedule mt16">
              Пн. —&nbsp;пт.: c&nbsp;10:00 до&nbsp;19:00 (МСК)
            </div>
            <div class="support__contacts mt16">
              <span class="support__email-button" @click="openContactUsModalWindow">Написать нам</span>
              <span
                class="support__phone-number-button"
                @click="openContactUsModalWindow({ isCallUs: true })"
              >Позвонить нам</span>
            </div>
            <div class="mt16">
              <span
                class="support__missing-product-button"
                @click="openMissingProductModalWindow"
              >Не&nbsp;нашли нужный товар?</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row footer-top">
        <div class="column-4 tb12 with-margin-bottom">
          <div class="title">
            Интернет-магазин
          </div>
          <ul class="links">
            <li v-for="x in onlineShopMenuItems" :key="x.name">
              <NuxtLink :to="x.path">
                {{ x.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="column-4 tb12 with-margin-bottom">
          <div class="title">
            Поддержка
          </div>
          <ul class="links">
            <li v-for="x in supportMenuItems" :key="x.name">
              <NuxtLink :to="x.path">
                {{ x.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="column-4 tb12 with-margin-bottom">
          <div class="title">
            Правовая информация
          </div>
          <ul class="links">
            <li v-for="x in informationMenuItems" :key="x.name">
              <a :href="x.url" target="_blank">
                {{ x.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="row">
          <div class="column-4 tb12 with-margin-bottom">
            <div class="mb16 restrictions">
              <div class="age">
                18+
              </div>
              <span>БАД. Не является лекарственным средством.</span>
            </div>
            <div class="copyright">
              <span>ООО «Ай&nbsp;ТАБ», 2020—2024. Все права защищены.</span>
            </div>
          </div>
          <div class="column-4 tb12 with-margin-bottom">
            <div class="payment-systems">
              <a
                v-for="x in paymentSystems"
                :key="x.href"
                :aria-label="x.ariaLabel"
                :href="x.href"
                target="_blank"
              ><SvgIcon :name="x.icon" /></a>
            </div>
          </div>
          <div class="column-4 tb12">
            <div class="app-marketplaces">
              <a
                v-for="x in appMarketplaces"
                :key="x.href"
                :aria-label="x.ariaLabel"
                :href="x.href"
                target="_blank"
              ><SvgIcon :name="x.icon" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

footer {
  @media screen and (max-width: 600px) {
    padding-bottom: 50px; // 50px is height of bottom navigation menu.
  }

  ul.links > li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .social-networks {
    font-size: 40px;

    a {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      margin-left: 12px;
      width: 40px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .support-wrapper {
    background: white;
    text-align: center;
  }

  .support {
    &__contacts {
      align-items: center;
      display: flex;
      justify-content: center;
      line-height: 16px;
    }

    &__email-button {
      color: $main-blue-color;
      border-right: 1px solid $main-light-gray-color;
      padding-right: 16px;
    }

    &__phone-number-button {
      color: $main-green-color;
      padding-left: 16px;
    }

    &__missing-product-button {
      color: $main-blue-color;
    }

    &__email-button,
    &__phone-number-button,
    &__missing-product-button {
      cursor: pointer;
      user-select: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &__schedule {
      color: $main-light-gray-color;
    }

    &__title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .social-networks,
  .support-wrapper {
    padding: 40px 16px;

    @media screen and (max-width: 1020px) {
      padding: 32px 16px;
    }
  }

  .social-networks,
  .support {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .footer-top {
    padding: 40px 0;

    @media screen and (max-width: 1020px) {
      padding: 32px 0;
    }

    .title {
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 12px;
    }
  }

  .footer-bottom {
    padding-bottom: 40px;

    @media screen and (max-width: 1020px) {
      padding-bottom: 32px;
    }

    .app-marketplaces,
    .copyright,
    .payment-systems,
    .restrictions {
      align-items: center;
      display: flex;
    }

    .app-marketplaces {
      font-size: 40px;
      justify-content: end;

      @media screen and (max-width: 880px) {
        justify-content: start;
      }

      a {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          width: auto;
        }
      }
    }

    .payment-systems {
      font-size: 16px;
      justify-content: center;

      @media screen and (max-width: 880px) {
        justify-content: start;
      }

      a {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          width: auto;
        }
      }
    }

    .restrictions {
      font-size: 12px;

      .age {
        background: $main-gray-color;
        border-radius: 4px;
        color: white;
        display: inline-block;
        line-height: 14px;
        padding: 4px 8px;
        white-space: nowrap;
      }

      & > span {
        margin-left: 8px;
      }
    }

    .copyright {
      font-size: 14px;
    }
  }
}
</style>
